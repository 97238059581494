<template>
  <div id="page" :class="$style.page">
    <AdsSection>
      <div
        v-for="{ adUnitId, dimensions } in ads"
        :key="adUnitId"
        :id="adUnitId"
        :class="$style.slot"
        :style="{
          width: getWidth(dimensions),
          height: getHeight(dimensions)
        }"
        :about="getWidth(dimensions)"
      >
        <p>
          <strong>{{ adUnitId }}</strong>
        </p>
        <p>( {{ getWidth(dimensions) }} x {{ getHeight(dimensions) }} )</p>
        <p v-if="dimensions.length > 1">( Mode: {{ isMobile() ? 'Mobile' : 'Desktop' }} )</p>
      </div>
    </AdsSection>
  </div>
</template>

<script>
import AdsSection from './components/AdsSection'

import ads from '@/src/page/models/ads'

export default {
  components: {
    AdsSection
  },

  data () {
    return {
      ads
    }
  },

  async mounted () {
    // Attach the display service script to the document's head
    await this.loadDisplayService()

    ads.forEach((ad) => {
      $linkfire.displayService.requestAd({
        ...ad,
        boxId: ad.adUnitId,
        partnerId: process.env.VUE_APP_PLAYGROUND_PARTNER_ID
      })
    })
  },

  methods: {
    loadDisplayService () {
      return new Promise((resolve) => {
        const script = document.createElement('script')

        script.async = true
        script.type = 'text/javascript'
        script.src = process.env.VUE_APP_DISPLAY_SERVICE_URL
        script.addEventListener('load', resolve)

        const headTag = document.querySelector('head')
        headTag.appendChild(script)
      })
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getWidth (dimensions) {
      return dimensions[0].length === 4 && this.isMobile() ? `${dimensions[0][2]}px` : `${dimensions[0][0]}px`
    },
    getHeight (dimensions) {
      return dimensions[0].length === 4 && this.isMobile() ? `${dimensions[0][3]}px` : `${dimensions[0][1]}px`
    }
  }
}
</script>

<style lang="scss" module>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}

* {
  box-sizing: border-box;
}

h1 {
  margin-top: 0;
}

.page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
</style>
