export default [
  {
    adUnitId: 'Dev_Ad_Unit_Dynamic_1',
    dimensions: [[728, 90, 300, 250]]
  },
  {
    adUnitId: 'Dev_Ad_Unit_Dynamic_2',
    dimensions: [[300, 250]]
  },
  {
    adUnitId: 'Dev_Ad_Unit_Dynamic_3',
    dimensions: [[320, 50]]
  },
  {
    adUnitId: 'Channel_Partners_Option_A_Dev',
    dimensions: [[320, 50]],
    hb: false
  }
]
