<template>
  <section :class="$style.root">
    <h1 :class="$style.heading">{{ heading }}</h1>
    <div :class="$style.ads">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      heading: 'Display Service'
    }
  }
}
</script>

<style module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-inline: auto;
  padding: 5rem 1rem;
}

.heading {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
}

.ads {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > div {
    margin: 1rem 0;
  }
}

.ads > * {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;

  p {
    margin: 0;
    font-size: 12px;
    user-select: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #f5f5f5;
    border: 1px dotted #ccc;
    content: ' ';
  }
}
</style>
